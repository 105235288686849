import { Link } from "react-router-dom";
import { ArrowRight } from "lucide-react";
import "./LandingStyles/CTASection.css";

export default function CTASection() {
  return (
    <section className="cta-section">
      <div className="cta-wrapper">
        <span className="cta-subtitle">Start building today!</span>
        <h2 className="cta-heading">Ready to work together?</h2>
        <p className="cta-description">
          Book your demo to know how BLDX can streamline your projects
          effectively.
        </p>
        {/* <Link to="/demo" className="cta-button">
          SCHEDULE DEMO
          <span className="arrow">→</span>
        </Link> */}
        <Link to="/schedule-demo">
          <button className="demo-button">
            SCHEDULE DEMO
            <span className="arrow">→</span>
          </button>
        </Link>
     
      </div>
    </section>
  );
}
