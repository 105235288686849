import "./LandingStyles/ScheduleInsights.css";
import health from "../assets/health.png";
import optimiser from "../assets/optimiser.png";

export default function ScheduleInsights() {
  return (
    <section className="insights-section">
      <div className="insights-container">
        <div className="insights-content">
          <div className="insights-text">
            <span className="ai-label bg-transparent">AI-Powered</span>
            <h2 className="insights-title">SCHEDULE INSIGHTS</h2>
            <p className="insights-description">
              Uncover key project insights with AI-driven analytics. Our
              advanced search goes beyond simple keywords to understand intent,
              helping you quickly find and interpret critical schedule data.
              <br />
              <b>Schedule Health Score</b> – Get a clear assessment of your
              project's overall health.
              <br />
              <b>Critical Path Analysis</b> – Identify bottlenecks and potential
              delays.
            </p>
          </div>

          <div className="insights-visual">
            <img
              src={health}
              alt="Schedule insights dashboard showing health score and analysis"
              className="insights-image"
            />
          </div>
        </div>
        <div className="bt">
          <div className="insights-content ">
            <div className="insights-visual">
              <img
                src={optimiser}
                alt="Schedule insights dashboard showing health score and analysis"
                className="insights-image"
              />
            </div>
            <div className="insights-text">
              <span className="ai-label bg-transparent">AI-Powered</span>
              <h2 className="insights-title">SCHEDULE OPTIMISER</h2>
              <p className="insights-description">
                Optimize your project schedule dynamically with AI-driven
                adjustments. BLDX analyzes real-time progress, constraints, and
                dependencies to generate an optimized schedule that keeps your
                project on track.
                <br />
                <b>Automated Schedule Adjustments</b> – AI detects bottlenecks
                and suggests optimized timelines.
                <br />
                <b>Resource Optimisation</b> – Reallocate labor and materials
                efficiently to minimize delays.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
