import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getProjectData } from "./AuthThunk"
interface AuthState {
  projectData: {
    project : any,
    company : String,
    subCompany :  any,
    employees :  any,
    schedule :  String

  };
  isProjectLoading: boolean;
  projectError: any;

  dashboardMetrics: {
    projectStatus: {
      homeProgressPercentage: number;
      homePendingProgressPercentage: number;
      homeinProgressPercentage :number;
    },
    laborStatus: {
      actualTotal: number;
      plannedTotal: number;
    },
    materialsStatus: {
      percentagePlanned: number;
      percentageReceived: number;
      percentageUsed: number;
    },
    constraintsStatus: {
      severityCounts: number;
      severityCountsHigh: number;
      severityCountsMed: number;
      severityCountsLow: number;
    }
  }
}

const initialState: AuthState = {
  projectData: {
    project : {},
    company : '',
    subCompany :  [],
    employees :  [],
    schedule :  'String'
  },
  isProjectLoading: false,
  projectError: null,

  dashboardMetrics: {
    projectStatus: {
      homeProgressPercentage: 0,
      homePendingProgressPercentage: 0,
      homeinProgressPercentage: 0,
    },
    laborStatus: {
      actualTotal: 0,
      plannedTotal: 0,
    },
    materialsStatus: {
      percentagePlanned: 0,
      percentageReceived: 0,
      percentageUsed: 0,
    },
    constraintsStatus: {
      severityCounts: 0,
      severityCountsHigh: 0,
      severityCountsMed: 0,
      severityCountsLow: 0,
    }
  }
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
       // Add actions for updating dashboard metrics
       updateProjectStatus(state, action: PayloadAction<{ completed: number; pending: number; inprogress:number }>) {
        state.dashboardMetrics.projectStatus.homeProgressPercentage = action.payload.completed;
        state.dashboardMetrics.projectStatus.homePendingProgressPercentage = action.payload.pending;
        state.dashboardMetrics.projectStatus.homeinProgressPercentage = action.payload.inprogress;
      },
      
      updateLaborStatus(state, action: PayloadAction<{ actual: number; planned: number }>) {
        state.dashboardMetrics.laborStatus.actualTotal = action.payload.actual;
        state.dashboardMetrics.laborStatus.plannedTotal = action.payload.planned;
      },
      
      updateMaterialsStatus(state, action: PayloadAction<{ 
        planned: number;
        received: number;
        used: number;
      }>) {
        state.dashboardMetrics.materialsStatus.percentagePlanned = action.payload.planned;
        state.dashboardMetrics.materialsStatus.percentageReceived = action.payload.received;
        state.dashboardMetrics.materialsStatus.percentageUsed = action.payload.used;
      },
      
      updateConstraintsStatus(state, action: PayloadAction<{
        total: number;
        high: number;
        medium: number;
        low: number;
      }>) {
        state.dashboardMetrics.constraintsStatus.severityCounts = action.payload.total;
        state.dashboardMetrics.constraintsStatus.severityCountsHigh = action.payload.high;
        state.dashboardMetrics.constraintsStatus.severityCountsMed = action.payload.medium;
        state.dashboardMetrics.constraintsStatus.severityCountsLow = action.payload.low;
      }
  },
  extraReducers : (builder) => {
    builder
      .addCase(getProjectData.pending, (state) => {
        state.isProjectLoading = true;
      })
      .addCase(getProjectData.fulfilled, (state, action) => {
          state.isProjectLoading = false;
          state.projectData = action.payload;
      })
      .addCase(getProjectData.rejected, (state,action) => {
        state.isProjectLoading = false;
        state.projectError = action.error
      })
  }
});

export const {
  updateProjectStatus,
  updateLaborStatus,
  updateMaterialsStatus,
  updateConstraintsStatus
} = authSlice.actions;


export default authSlice.reducer;
