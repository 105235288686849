import MainNavbar from "./MainNavbar";
import CTASection from "./CTASection";
import Footer from "./Footer";
import Solutions from "./Solutions";
import WhoWeServe from "./WhoWeServe";

const SolutionsPage = () => {
  return (
    <>
      <MainNavbar />
      <Solutions/>
      <WhoWeServe/>
      <CTASection />
      <Footer />
    </>
  );
};

export default SolutionsPage;
