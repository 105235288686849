import "./LandingStyles/ConstructionDashboard.css";
import ImageRight from "../assets/p1.png";

export default function ConstructionDashboard() {
  return (
    <div className="dashboard">
      <div className="dashboard-left">
        <div className="dashboard-left-content">
          <span className="platform-label">Platform</span>
          <h1 className="dashboard-title">Construction Dashboard</h1>
          <p className="dashboard-description">
            Your command center for construction projects—track progress,
            resolve constraints, and manage resources effortlessly. Stay
            informed with real-time insights and ensure seamless collaboration
            across teams.
          </p>
        </div>
      </div>

      <div className="dashboard-right">
        <img
          src={ImageRight}
          alt="Construction Dashboard Interface showing project status, labor metrics, and activity assignments"
          className="dashboard-interface"
        />
      </div>
    </div>
  );
}
