import "./LandingStyles/TCECaseStudy.css";
import tce from "../assets/tce.png";
import tc1 from "../assets/tc1.svg";
import tc2 from "../assets/tc2.svg";
import tc3 from "../assets/tc3.svg";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  BarChart,
  Bar,
} from "recharts";
const TCECaseStudy = () => {
  const data = [
    { name: "Week 1", planned: 10, actual: 8 },
    { name: "Week 2", planned: 25, actual: 20 },
    { name: "Week 3", planned: 35, actual: 32 },
    { name: "Week 4", planned: 50, actual: 49 },
    { name: "Week 5", planned: 65, actual: 63 },
    { name: "Week 6", planned: 75, actual: 78 },
    { name: "Week 7", planned: 85, actual: 88 },
    { name: "Week 8", planned: 100, actual: 98 },
  ];
  const barChartData = [
    { name: "Data Entry Time", before: 100, with: 50 },
    { name: "Decision Speed", before: 100, with: 300 },
    { name: "Reporting Time", before: 100, with: 60 },
    { name: "Schedule Accuracy", before: 70, with: 95 },
  ];
  return (
    <>
      <section>
        <div className="TCECaseStudy-section">
          <div className="TCECaseStudy-container">
            <span className="TCECaseStudy-label">Case Study</span>
            <h1 className="TCECaseStudy-title">
              Tata Consulting Engineers (TCE) |TEPL Marigold Phase 2 – Anno 2
            </h1>
            <p className="TCECaseStudy-description">
              Transforming Construction Management with AI-Powered Insights
            </p>
            <img src={tce} alt="" className="tce-img" />
          </div>
        </div>
      </section>
      <section className="summary-section">
        <div className="summary-container">
          {/* Header */}
          <div className="summary-header">
            <span className="summary-label">summary</span>
            <h2 className="summary-title">Executive Summary</h2>
            <p className="summary-description">
              The BLDX pilot at TCE has revolutionized project management
              through real-time scheduling, intelligent material tracking, and
              AI-driven insights. This case study highlights how our platform
              delivered measurable improvements including a 50% reduction in
              manual data entry, faster decisionmaking through intuitive
              dashboards, and enhanced forecasting for schedule risks. Discover
              how BLDX is set to transform construction management at TCE and
              beyond.
            </p>
          </div>
        </div>
      </section>
      <section className="evaluation-container">
        <div className="evaluation-content">
          <div className="evaluation-header">
            <div className="badge">Evaluation</div>
            <h2>KPI Status Evaluation</h2>
            <p className="description text-center">
              KPI Status Evaluation improves efficiency with Daily Reports
              Integration (50% less manual entry), Material Tracking (AI
              prevents stockouts), and Predictability (30% fewer schedule
              revisions). These enhancements boost visibility, streamline
              operations, and enable proactive risk management for better
              project outcomes.
            </p>
          </div>

          <div className="feature-cards">
            <div className="feature-card">
              <div className="icon-wrapper">
                {/* <BarChart3 size={24} /> */}
                <img src={tc1} alt="" className="p-0" />
              </div>
              <h3>Connecting Daily Reports to Schedule</h3>
              <p style={{ marginBottom: "0.5rem" }}>
                <b>What's Working:</b> Real-time integration of daily progress
                updates with project schedules, enabling instant visibility into
                project status.
              </p>
              <p>
                <b>Key Impact:</b> 50% reduction in manual data re-entry and 50%
                improvement in progress accuracy.
              </p>
            </div>

            <div className="feature-card">
              <div className="icon-wrapper">
                {/* <Link2 size={24} /> */}
                <img src={tc2} alt="" className="p-0" />
              </div>
              <h3>Material Tracking</h3>
              <br />
              <p style={{ marginBottom: "0.5rem" }}>
                <b>What's Working:</b> Automated alerts for inventory thresholds
                and AI-driven monitoring of long-lead materials to prevent
                stockouts.
              </p>
              <p>
                <b>Key Impact:</b> Significant reduction in material-related
                delays and improved supply chain transparency.
              </p>
            </div>

            <div className="feature-card">
              <div className="icon-wrapper">
                <img src={tc3} alt="" className="p-0" />
              </div>
              <h3>Predictability</h3>
              <br />
              <p style={{ marginBottom: "0.5rem" }}>
                <b>What's Working:</b> AI-driven predictive analytics providing
                early warnings on potential schedule slippages before they
                impact timelines.
              </p>
              <p>
                <b>Key Impact:</b> 30% reduction in schedule revisions with
                proactive risk management.
              </p>
            </div>
          </div>

          <div className="chart-container">
            <p>
              <b>Project Progress Comparision</b>
            </p>
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart
                data={data}
                margin={{ top: 40, right: 30, left: 50, bottom: 50 }} // Increased top margin for legend
              >
                <defs>
                  <linearGradient id="colorPlanned" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#1782e0" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#1782e0" stopOpacity={0} />
                  </linearGradient>
                  <linearGradient id="colorActual" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#E87D1F" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#E87D1F" stopOpacity={0} />
                  </linearGradient>
                </defs>

                {/* X Axis with Label */}
                <XAxis
                  dataKey="name"
                  label={{
                    value: "Weeks",
                    position: "bottom",
                    dy: 20,
                    fontSize: 16,
                  }}
                />

                {/* Y Axis with Label */}
                <YAxis
                  label={{
                    value: "Completion Progress",
                    angle: -90,
                    position: "left",
                    dx: -10,
                    dy: -50,
                    fontSize: 16,
                  }}
                />

                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip
                  formatter={(value, name) =>
                    name === "planned"
                      ? [`${value}`, "Planned Progress"]
                      : [`${value}`, "Actual Progress"]
                  }
                />

                {/* ✅ Legend at the Top */}
                <Legend verticalAlign="top" align="center" />

                <Area
                  type="monotone"
                  dataKey="planned"
                  stroke="#1782e0"
                  fillOpacity={1}
                  fill="url(#colorPlanned)"
                  name="Planned Progress"
                />
                <Area
                  type="monotone"
                  dataKey="actual"
                  stroke="#E87D1F"
                  fillOpacity={1}
                  fill="url(#colorActual)"
                  name="Actual Progress"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>
      </section>
      <section className="summary-section">
        <div className="summary-container">
          {/* Header */}
          <div className="summary-header">
            <span className="summary-label">Operations</span>
            <h2 className="summary-title">Impact on PMC Operations</h2>
            <p className="summary-description">
              The implementation of BLDX at TCE has transformed project
              management operations with measurable benefits across multiple
              areas:
            </p>
          </div>
        </div>
        <div className="feature-cards operations-cards">
          <div className="feature-card">
            <h2>50%</h2>
            <h3>DATA ENTRY REDUCTION</h3>
            <p>Less time spent on manual reporting</p>
          </div>

          <div className="feature-card">
            <h2>3x</h2>
            <h3>DECISION SPEED</h3>
            <p>Faster decision-making with real-time dashboards</p>
          </div>

          <div className="feature-card">
            <h2>95%</h2>
            <h3>REPORTING ACCURACY</h3>
            <p>Improved financial and progress reporting</p>
          </div>
          <div className="feature-card">
            <h2>24/7</h2>
            <h3>COMMUNICATION</h3>
            <p>Enhanced through automated alerts</p>
          </div>
        </div>
        <div className="chart-container">
          <p>
            <b>Performance Improvement Comparison</b>
          </p>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={barChartData}
              margin={{ top: 40, right: 30, left: 50, bottom: 50 }} // Increased top margin for legend
            >
              {/* X Axis with Label */}
              <XAxis
                dataKey="name"
                label={{
                  value: "Performance Parameters",
                  position: "bottom",
                  dy: 20,
                  fontSize: 16,
                }}
              />

              {/* Y Axis with Label */}
              <YAxis
                label={{
                  value: "Relative Performance (%)",
                  angle: -90,
                  position: "left",
                  dx: -10,
                  dy: -50,
                  fontSize: 16,
                }}
              />

              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip
                formatter={(value, name) =>
                  name === "before"
                    ? [`${value}`, "Before BLDX"]
                    : [`${value}`, "With BLDX"]
                }
              />

              {/* ✅ Legend at the Top */}
              <Legend verticalAlign="top" align="center" />

              {/* ✅ Bars for Data */}
              <Bar
                dataKey="before"
                fill="#1782e0"
                barSize={80}
                name="Before BLDX"
              />
              <Bar
                dataKey="with"
                fill="#E87D1F"
                barSize={80}
                name="With BLDX"
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </section>
      <section className="summary-section">
        <div className="summary-container">
          {/* Header */}
          <div className="summary-header">
            <span className="summary-label">Future</span>
            <h2 className="summary-title">Scalability & Future Projects</h2>
            <p className="summary-description mt-0 mb-4 ">
              Learnings from the Anno 2 pilot create a robust foundation for
              implementing BLDX across TCE's project portfolio:
            </p>
            <p className="summary-description mt-0 mb-4 ">
              <b>&bull; Process Replication:</b> Standardized approach to daily
              reporting, material tracking, and AI insights
            </p>
            <p className="summary-description mt-0 mb-4 ">
              <b>&bull; Faster Onboarding:</b> Streamlined training using best
              practices from Anno 2 pilot
            </p>
            <p className="summary-description mt-0 mb-4 ">
              <b>&bull; Phased Implementation:</b> Strategic rollout across
              multiple projects for maximum impact
            </p>
          </div>
        </div>
        <div className="timeline-container">
          <div className="timeline-rightbox">
            <div className="timeline-rb-container">
              <ul className="timeline-rb">
                <li className="timeline-rb-item timeline-rb-item1" ng-repeat="itembx">
                  <div className="timeline-timestamp ">
                  Pilot Phase
                  Complete
                  </div>
                  {/* <div className="timeline-item-title">
                    Chris Serrano posted a photo on your wall.
                  </div> */}
                </li>
                <li className="timeline-rb-item" ng-repeat="timeline-itembx">
                  <div className="timeline-timestamp">
                  Anno 1
                  Integration
                  </div>
                  {/* <div className="timeline-item-title">
                    Mia Redwood commented on your last post.
                  </div> */}
                </li>

                <li className="timeline-rb-item" ng-repeat="timeline-itembx">
                  <div className="timeline-timestamp">
                  Full Enterprise
                  Deployment
                  </div>
                  {/* <div className="timeline-item-title">
                    Lucas McAlister just send you a message.
                  </div> */}
                </li>
                <li className="timeline-rb-item" ng-repeat="timeline-itembx">
                  <div className="timeline-timestamp">
                  Advanced AI
                  Features
                  </div>
                  {/* <div className="timeline-item-title">
                    Lucas McAlister just send you a message.
                  </div> */}
                </li>
                <li className="timeline-rb-item" ng-repeat="timeline-itembx">
                  <div className="timeline-timestamp">
                  Ecosystem
                  Integration
                  </div>
                  {/* <div className="timeline-item-title">
                    Lucas McAlister just send you a message.
                  </div> */}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TCECaseStudy;
