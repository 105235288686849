import * as Sentry from "@sentry/react";
import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
// import { ReactQueryDevtools } from "react-query/devtools";
import AppRoutes from "./AppRoutes";
import AuthProvider from "./auth/contexts/AuthProvider";
import Loader from "./core/components/Loader";
import QueryWrapper from "./core/components/QueryWrapper";
import SettingsProvider from "./core/contexts/SettingsProvider";
import SnackbarProvider from "./core/contexts/SnackbarProvider";
import usePageTracking from "./core/hooks/usePageTracking";
import './App.css'
import store from "./store";
import { Provider } from "react-redux";
if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN
  });
}

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 0,
      suspense: true
    }
  }
});

function App() {
  usePageTracking();

  return (
    <React.Suspense fallback={<Loader />}>
      <Sentry.ErrorBoundary fallback={<div>An error has occurred</div>}>
        <QueryClientProvider client={queryClient}>
          <SettingsProvider>
            <QueryWrapper>
            <Provider store={store}>
              <SnackbarProvider>
                <AuthProvider>
                  <AppRoutes />
                </AuthProvider>
              </SnackbarProvider>
            </Provider>
            </QueryWrapper>
          </SettingsProvider>
          {/* <ReactQueryDevtools initialIsOpen /> */}
        </QueryClientProvider>
      </Sentry.ErrorBoundary>
    </React.Suspense>
  );
}

export default App;
