import MainNavbar from "./MainNavbar";
import ProjectSchedule from "./ProjectSchedule";
import CTASection from "./CTASection";
import Footer from "./Footer";
import ScheduleInsights from "./ScheduleInsights";
import ScheduleVariation from "./ScheduleVariation";
import ScrollToTop from "./ScrollToTop";

const SchedulePage = () => {
  return (
    <>
      <MainNavbar />
      <div className="Schedule-Container">
        <ScrollToTop />
        <ProjectSchedule />
        <ScheduleInsights />
        <ScheduleVariation />
      </div>
      <CTASection />
      <Footer />
    </>
  );
};

export default SchedulePage;
