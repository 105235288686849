import "./LandingStyles/ProjectSchedule.css";
import content from "../assets/Content.png";

export default function ProjectSchedule() {
  return (
    <section>
      <div className="project-schedule-section">
        <div className="project-schedule-container">
          <span className="project-platform-label">Platform</span>
          <h1 className="project-schedule-title">Project Schedule</h1>
          <p className="project-schedule-description">
            Your central hub for planning, tracking, and optimizing construction
            projects. BLDX streamlines scheduling by integrating real-time data,
            AI-powered insights, and seamless collaboration—ensuring your
            project stays on track from start to finish.
          </p>
        </div>
      </div>
      <img src={content} alt="" className="w-full m-8-ve" />
    </section>
  );
}
