import "./LandingStyles/ScheduleVariation.css";

export default function ScheduleVariation() {
  return (
    <section className="variation-section">
      <div className="variation-container">
        <span className="tracker-label">Tracker</span>
        <h1 className="variation-title">SCHEDULE VARIATION</h1>
        <div className="variation-content">
          <h2 className="variation-subtitle">
            <b>Test different scenarios</b>
            <span className="variation-dash"> – </span>
            Compare different scheduling strategies to find the best path
            forward.
          </h2>
        </div>
      </div>
    </section>
  );
}
