import MainNavbar from "./MainNavbar";
import CTASection from "./CTASection";
import Footer from "./Footer";
import ConstructionDashboard from "./ConstructionDashboard";
import TrackingFeatures from "./TrackingFeatures";
import Reports from "./Reports";
import ScrollToTop from "./ScrollToTop";

const DashboardPage = () => {
  return (
    <>
      <MainNavbar /> 
      <ScrollToTop />
      <ConstructionDashboard />
      <TrackingFeatures />
      <Reports />
      <CTASection />
      <Footer />
    </>
  );
};

export default DashboardPage;
