import "./LandingStyles/ComingSoon.css";
import cs1 from "../assets/cs1.png";
import cs2 from "../assets/cs2.png";

export default function ComingSoon() {
  return (
    <section className="coming-section">
      <div className="coming-container">
        <h2 className="coming-title">COMING SOON</h2>

        <div className="feature-grid">
          {/* Revision Control Card */}
          <div className="cs-feature-card">
            <div className="feature-image-container">
              <img
                src={cs1}
                alt="Revision control interface showing document version management"
                className="feature-image"
              />
            </div>
            <div className="cs-feature-card-container">
              <h3 className="feature-title">Revision Control</h3>
              <p className="feature-description">
                Track document changes, manage approvals, and maintain version
                history effortlessly.
              </p>
            </div>
          </div>

          {/* Document Compare Card */}
          <div className="cs-feature-card">
            <div className="feature-image-container">
              <img
                src={cs2}
                alt="Document comparison interface showing architectural drawings"
                className="feature-image"
              />
            </div>
            <div className="cs-feature-card-container">
              <h3 className="feature-title">Document Compare</h3>
              <p className="feature-description">
                Detect discrepancies across versions and optimize resource
                planning.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
