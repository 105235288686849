import "./LandingStyles/WhoWeServe.css";
import { Building2, Construction, LayoutPanelTop } from "lucide-react";
import si1 from "../assets/si1.png";
import si2 from "../assets/si2.png";
import s1 from "../assets/s1.svg";
import s2 from "../assets/s2.svg";
import s3 from "../assets/s3.svg";

export default function WhoWeServe() {
  return (
    <section className="serve-section">
      <div className="serve-container">
        <div className="serve-header">
          <h2 className="serve-title">WHO WE SERVE</h2>
          <p className="serve-description">
            Gain Unmatched Visibility and Precision Over Your Project's
            Forecast, Labor, and Constraints to Drive Success.
          </p>
        </div>

        <div className="main-cards">
          {/* Main Contractor Card */}
          <div className="main-card">
            <div className="card-image">
              <img
                src={si1}
                alt="Construction manager using tablet at construction site"
                className="contractor-image"
              />
            </div>
            <h3 className="card-title">Main Contractor</h3>
            <p className="card-description">
              Stay ahead of project risks with predictive scheduling and
              resource optimization to deliver projects on time and within
              budget.
            </p>
          </div>

          {/* Sub Contractor Card */}
          <div className="main-card">
            <div className="card-image">
              <img
                src={si2}
                alt="Three construction professionals reviewing documents at site"
                className="contractor-image"
              />
            </div>
            <h3 className="card-title">Sub Contractor</h3>
            <p className="card-description">
              Streamline task coordination, labor scheduling, and progress
              tracking for seamless collaboration on-site.
            </p>
          </div>
        </div>

        <div className="service-cards">
          {/* Real Estate Companies */}
          <div className="service-card">
            {/* <div className="service-icon"> */}
              {/* <Building2 className="icon" /> */}
            {/* </div> */}
              <img src={s1} alt="" />
            <h3 className="service-title">Real Estate Companies</h3>
            <p className="service-description">
              Enhance project forecasting and stay proactive with market-driven
              insights that optimize construction and sales cycles.
            </p>
          </div>

          {/* Developers */}
          <div className="service-card">
            {/* <div className="service-icon">
              <Construction className="icon" />
            </div> */}
              <img src={s2} alt="" />
            <h3 className="service-title">Developers</h3>
            <p className="service-description">
              Make data-informed decisions to accelerate timelines, manage
              costs, and maintain quality across multiple projects.
            </p>
          </div>

          {/* Project Management Companies */}
          <div className="service-card">
            {/* <div className="service-icon">
              <LayoutPanelTop className="icon" />
            </div> */}
              <img src={s3} alt="" />
            <h3 className="service-title">Project Management Companies</h3>
            <p className="service-description">
              Leverage AI-powered analytics to gain full visibility into labor
              productivity, material usage, and project constraints.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
