import { useState, useEffect, useRef } from "react";
import { ChevronDown } from "lucide-react";
import { Link } from "react-router-dom";
import "./LandingStyles/MainNavbar.css";
import logo from "../assets/bldxLogo.png";
import pimg from "../assets/pimg.png";
import simg from "../assets/solutionImg.png";
import picon from "../assets/platformIcon.svg";
import sicon from "../assets/sIcon.svg";

export default function Navigation() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState<string | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const navItems = [
    { label: "Platform", hasDropdown: true, to: "#" },
    { label: "Solutions", hasDropdown: true, to: "/solutions" },
    // { label: "Tools", hasDropdown: true, to: "/bldx" },
    // { label: "Resources", hasDropdown: false, to: "/bldx" },
    // { label: "Contact us", to: "/bldx" },
  ];

  const handleDropdownToggle = (label: string) => {
    setActiveDropdown(activeDropdown === label ? null : label);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    // document.body.style.overflow = !isMenuOpen ? "hidden" : "auto";
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setActiveDropdown(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 1024);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <nav className="nav-container">
      <div className="nav-content">
        <div className="nav-subItem">
          <Link to="/">
            <img className="nav-logo" src={logo} alt="" />
          </Link>

          <button
            className={`hamburger ${isMenuOpen ? "active" : ""}`}
            onClick={toggleMenu}
            aria-label="Toggle menu"
          >
            <span className="hamburger-line"></span>
            <span className="hamburger-line"></span>
            <span className="hamburger-line"></span>
          </button>

          {isMenuOpen && (
            <div className="mobile-overlay" onClick={toggleMenu}></div>
          )}

          <div
            className={`nav-items-container ${isMenuOpen ? "open" : ""}`}
            ref={dropdownRef}
          >
            <ul className="nav-items">
              {navItems.map((item) => (
                <li
                  key={item.label}
                  className={`nav-item ${
                    activeDropdown === item.label ? "active" : ""
                  }`}
                  onClick={() => handleDropdownToggle(item.label)}
                >
                  <div className="nav-item-content">
                    <div className="item-label">{item.label}</div>
                    {item.hasDropdown && (
                      <button
                        className="dropdown-toggle"
                        onClick={() => handleDropdownToggle(item.label)}
                        aria-label={`Toggle ${item.label} dropdown`}
                      >
                        <ChevronDown
                          className={`dropdown-arrow ${
                            activeDropdown === item.label ? "active" : ""
                          }`}
                          size={16}
                        />
                      </button>
                    )}
                  </div>
                  {item.hasDropdown &&
                    activeDropdown === item.label &&
                    (isMobile ? (
                      <div className="dropdown-menu">
                        {item.label === "Platform" && (
                          <>
                            <Link
                              to="/platform/dashboard"
                              className="dropdown-item"
                            >
                              Dashboard
                            </Link>
                            <Link
                              to="/platform/schedule"
                              className="dropdown-item"
                            >
                              Schedule
                            </Link>
                            <Link
                              to="/platform/document"
                              className="dropdown-item"
                            >
                              Documents
                            </Link>
                          </>
                        )}

                        {item.label === "Solutions" && (
                          <>
                            <Link
                              to="/solutions/who-we-serve"
                              className="dropdown-item"
                            >
                              Who we serve
                            </Link>
                            <Link
                              to="/solutions/case-studies"
                              className="dropdown-item"
                            >
                              Case Studies
                            </Link>
                            <Link
                              to="/solutions/tools"
                              className="dropdown-item"
                            >
                              Access-Free Tools
                            </Link>
                          </>
                        )}

                        {/* {item.label === "Tools" && (
                          <>
                            <Link
                              to="/tools/collaboration"
                              className="dropdown-item"
                            >
                              Collaboration
                            </Link>
                            <Link
                              to="/tools/automation"
                              className="dropdown-item"
                            >
                              Automation
                            </Link>
                            <Link
                              to="/tools/integrations"
                              className="dropdown-item"
                            >
                              Integrations
                            </Link>
                          </>
                        )} */}
                      </div>
                    ) : (
                      <div className="nav-menu">
                        {item.label === "Platform" && (
                          <div className="nav-menu-container">
                            {/* Platform Preview Section */}
                            <div className="platform-section">
                              <h2 className="platform-title">Platform</h2>
                              <p className="platform-description">
                                Streamline your construction projects with
                                AI-powered tracking, scheduling, and document
                                management. Gain real-time insights and optimize
                                resources effortlessly.
                              </p>
                              <img
                                src={pimg}
                                alt=""
                                style={{ maxWidth: "360px" }}
                              />
                            </div>

                            {/* Dashboard Section */}
                            <Link
                              to="/platform/dashboard"
                              className="menu-link"
                            >
                              <div className="menu-section primary-border">
                                <img src={picon} alt="" />
                                <div>
                                  <h2 className="menu-title">
                                    {/* <Link to="/platform/dashboard">Dashboard</Link> */}
                                    Dashboard
                                  </h2>
                                  <ul className="menu-list">
                                    <li>Project Overview</li>
                                    <li>Progress Tracking</li>
                                    <li>Constraint Tracking</li>
                                    <li>Resource Tracking</li>
                                    <li>Labor & Material Report</li>
                                    <li>Daily Report</li>
                                    <li>Project Status Report</li>
                                  </ul>
                                </div>
                              </div>
                            </Link>
                            {/* Schedule Section */}
                            <Link to="/platform/schedule" className="menu-link">
                              <div className="menu-section primary-border">
                                <img src={picon} alt="" />
                                <div>
                                  <h2 className="menu-title">Schedule</h2>
                                  <ul className="menu-list">
                                    <li>Schedule Insights</li>
                                    <li>Schedule Optimizer</li>
                                    <li>
                                      Schedule Variations
                                      <span className="coming-soon">
                                        {" "}
                                        (Coming Soon)
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </Link>
                            {/* Documents Section */}
                            <Link to="/platform/document" className="menu-link">
                              <div className="menu-section primary-border">
                                <img src={picon} alt="" />
                                <div>
                                  <h2 className="menu-title">Documents</h2>
                                  <ul className="menu-list">
                                    <li>Documents AI</li>
                                    <li>Document Insights</li>
                                    <li>
                                      Revision Control
                                      <span className="coming-soon">
                                        {" "}
                                        (Coming Soon)
                                      </span>
                                    </li>
                                    <li>
                                      Document Compare
                                      <span className="coming-soon">
                                        {" "}
                                        (Coming Soon)
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </Link>
                          </div>
                        )}
                        {item.label === "Solutions" && (
                          <div className="nav-menu-container">
                            {/* Platform Preview Section */}
                            <div className="platform-section">
                              <h2 className="platform-title">Solutions</h2>
                              <p className="platform-description">
                                AI-powered solutions designed to enhance
                                efficiency, optimize resources, and simplify
                                construction management. Transform the way you
                                plan, track, and execute projects.
                              </p>
                              <img
                                src={simg}
                                className="primary-border"
                                style={{
                                  borderRadius: "8px",
                                  maxWidth: "360px",
                                }}
                                alt=""
                              />
                            </div>

                            {/* Who we serve Section */}
                            <Link
                              to="/solutions/who-we-serve"
                              className="menu-link"
                            >
                              <div className="menu-section primary-border">
                                <img src={sicon} alt="" />
                                <div>
                                  <h2 className="menu-title">Who we serve</h2>
                                  <ul className="menu-list">
                                    <li>Main Contractor</li>
                                    <li>Subcontractor</li>
                                    <li>Real Estate Companies</li>
                                    <li>Developers</li>
                                    <li>Project Management Companies</li>
                                  </ul>
                                </div>
                              </div>
                            </Link>
                            {/* Case Studies Section */}
                            <Link
                              to="/solutions/case-studies"
                              className="menu-link"
                            >
                              <div className="menu-section primary-border">
                                <img src={sicon} alt="" />
                                <div>
                                  <h2 className="menu-title">Case Studies</h2>
                                  <ul className="menu-list">
                                    <li>Tata Consulting Engineers</li>
                                  </ul>
                                </div>
                              </div>
                            </Link>
                            {/* <Link to="/solutions/tools" className="menu-link"> */}
                              <div className="menu-section primary-border">
                                <img src={sicon} alt="" />
                                <div>
                                  <h2 className="menu-title">
                                    Access-Free Tools
                                  </h2>
                                  <ul className="menu-list">
                                    <li>
                                    AI Project Delay Calculator <br />
                                      <span className="coming-soon">
                                        {" "}
                                        (Coming Soon)
                                      </span>
                                    </li>
                                    <li>
                                    AI Schedule Optimizer Lite <br />
                                      <span className="coming-soon">
                                        {" "}
                                        (Coming Soon)
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            {/* </Link> */}
                          </div>
                        )}
                      </div>
                    ))}
                </li>
              ))}
            </ul>

            <div className="nav-buttons mobile-buttons">
              <a
                href="https://cal.com/getbldx"
                className="btn btn-primary"
                target="_blank"
                rel="noopener noreferrer"
              >
                SCHEDULE DEMO
              </a>

              {/* <button className="btn btn-secondary">LOG IN</button> */}
              <Link to="/login">
                <button className="btn btn-secondary">LOG IN</button>
              </Link>
            </div>
          </div>
        </div>
        <div className="nav-buttons desktop-buttons">
          <Link to="/login">
            <button className="btn btn-secondary">LOG IN</button>
          </Link>
          <Link to="/schedule-demo">
            <button className="btn btn-primary custom-btn">
              SCHEDULE DEMO
            </button>
          </Link>
        </div>
      </div>
    </nav>
  );
}
