import React, { useEffect } from "react";
import logo from "../assets/loaderImage.png";

const Loader: React.FC = () => {
  useEffect(() => {
    document.body.style.overflow = "hidden"; // Prevent page scrolling while loader is active

    return () => {
      document.body.style.overflow = "unset"; // Re-enable page scrolling after loader is gone
    };
  }, []);

  return (
    <div className="loader-overlay">
      <div className="loader-container">
        <img
          src={logo}
          alt="Loading"
          style={{ width: "100px", height: "100px" }}
        />
      </div>
    </div>
  );
};

export default Loader;

// // // Loader.tsx
// import React, { useEffect } from "react";
// import logo from "../assets/loaderImage.png";

// interface LoaderProps {
//   size?: number;
//   isLoading?: boolean;
//   className?: string;
// }

// const Loader: React.FC<LoaderProps> = ({
//   size = 100,
//   isLoading = true,
//   className = "",
// }) => {
//   useEffect(() => {
//     if (isLoading) {
//       document.body.style.overflow = "hidden";
//     } else {
//       document.body.style.overflow = "unset";
//     }

//     return () => {
//       document.body.style.overflow = "unset";
//     };
//   }, [isLoading]);

//   if (!isLoading) return null;

//   return (
//     <div className={`loader-overlay`}>
//       <div className="loader-container">
//         <img
//           src={logo}
//           alt="Company Logo"
//           className="loader-image"
//           style={{ width: `160px`, height: `160px` }}
//         />
//       </div>
//     </div>
//   );
// };

// export default Loader;
// import Logo from "./Logo";

// const Loader = () => {
//   return (
//     <Logo
//       size={100}
//       sx={{
//         "@keyframes pulse": {
//           "0%": {
//             opacity: 1,
//           },
//           "50%": {
//             opacity: 0.4,
//           },
//           "100%": {
//             opacity: 1,
//           },
//         },
//         animation: "pulse 1.5s ease-in-out 0.5s infinite",
//         textAlign: "center",
//         px: 3,
//         py: 8,
//       }}
//     />
//   );
// };

// export default Loader;
