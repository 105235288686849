import MainNavbar from "./MainNavbar";
import CTASection from "./CTASection";
import Footer from "./Footer";
import ProjectDocuments from "./ProjectDocuments";
import DocumentsAI from "./DocumentsAI";
import ComingSoon from "./ComingSoon";

import ScrollToTop from "./ScrollToTop";

const DocumentsPage = () => {
  return (
  <>
      <MainNavbar />
      <ScrollToTop/>
      <ProjectDocuments/>
      <DocumentsAI/>
      <ComingSoon/>
      <CTASection />
      <Footer />
    </>
  );
};

export default DocumentsPage;
