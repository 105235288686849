import { Link } from "react-router-dom";
import "./LandingStyles/Footer.css";
import logo from "../assets/bldxLogo.png";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-grid">
          {/* Logo and Address */}
          <div className="footer-logo-section">
            <Link to="/" className="footer-logo-link">
              <img src={logo} alt="BLDX Logo" className="footer-logo" />
            </Link>
            <div className="footer-address">
              <p className="footer-company-name">BLDO Technologies Pvt. Ltd.</p>
              <p className="mpt-0">
                WeWork Prestige Cube, Site No. 26, Laskar Hosur Road,
                <br />
                Adugodi, Koramangala, Bengaluru, Karnataka 560030.
              </p>
            </div>
          </div>

          {/* Navigation Links */}
          <div className="footer-nav-links">
            <Link to="#" className="footer-link">
              About us
            </Link>
            <Link to="/platform/dashboard" className="footer-link">
              Platform
            </Link>
            {/* <Link to="/resources" className="footer-link">
              Resources
            </Link> */}
            <Link to="/solutions" className="footer-link">
              Solution
            </Link>
          </div>

          {/* Contact Info */}
          <div className="footer-contact-section">
            <p className="footer-contact-title">Contact:</p>
            <a href="tel:+917066774747" className="footer-underline-link">
              +91 7066774747
            </a>
            <a href="mailto:team@getbldx.com" className="footer-underline-link">
              team@getbldx.com
            </a>
          </div>

          <div className="footer-nav-links">
            <Link to="/privacy-policy" className="footer-underline-link">
              Privacy Policy
            </Link>
            <Link to="/terms-and-conditions" className="footer-underline-link">
              Terms of Service
            </Link>
          </div>
        </div>

        {/* Bottom Section */}
        <div className="footer-bottom-section">
          <p className="footer-copyright">© 2025 BLDX. All rights reserved.</p>
          <div className="footer-bottom-links">
            <a
              href="https://www.linkedin.com/company/getbldx"
              target="_blank"
              rel="noopener noreferrer"
              className="footer-social-link"
              aria-label="Visit our LinkedIn page"
            >
              <svg
                width="26"
                height="26"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clipPath="url(#clip0_749_65014)">
                  <g clipPath="url(#clip1_749_65014)">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M5 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V5C3 3.89543 3.89543 3 5 3ZM8 18C8.27614 18 8.5 17.7761 8.5 17.5V10.5C8.5 10.2239 8.27614 10 8 10H6.5C6.22386 10 6 10.2239 6 10.5V17.5C6 17.7761 6.22386 18 6.5 18H8ZM7.25 9C6.42157 9 5.75 8.32843 5.75 7.5C5.75 6.67157 6.42157 6 7.25 6C8.07843 6 8.75 6.67157 8.75 7.5C8.75 8.32843 8.07843 9 7.25 9ZM17.5 18C17.7761 18 18 17.7761 18 17.5V12.9C18.0325 11.3108 16.8576 9.95452 15.28 9.76C14.177 9.65925 13.1083 10.1744 12.5 11.1V10.5C12.5 10.2239 12.2761 10 12 10H10.5C10.2239 10 10 10.2239 10 10.5V17.5C10 17.7761 10.2239 18 10.5 18H12C12.2761 18 12.5 17.7761 12.5 17.5V13.75C12.5 12.9216 13.1716 12.25 14 12.25C14.8284 12.25 15.5 12.9216 15.5 13.75V17.5C15.5 17.7761 15.7239 18 16 18H17.5Z"
                      fill="#1782E0"
                    />
                  </g>
                </g>
                <defs>
                  <clipPath id="clip0_749_65014">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                  <clipPath id="clip1_749_65014">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
