import "./LandingStyles/ProjectDocuments.css";
import pd from "../assets/pd.png"

export default function ProjectDocuments() {
  return (
    <section className="documents-section">
      <div className="documents-container">
        <div className="documents-content">
          <div className="documents-text">
            <span className="platform-label">Platform</span>
            <h2 className="documents-title">Project Documents</h2>
            <p className="documents-description">
              Your single source of truth for construction documents—organized,
              searchable, and AI-powered for instant insights. Say goodbye to
              manual searches and version confusion. With BLDX, access, manage,
              and collaborate on project files effortlessly.
            </p>
          </div>

          <div className="documents-visual">
            <img
              src={pd}
              alt="Project documents interface showing architectural drawings and plans"
              className="pd-interface-image"
            />
          </div>
        </div>
      </div>
    </section>
  );
}
