import Footer from "./Footer";
import MainNavbar from "./MainNavbar";
import "./LandingStyles/ScheduleDemo.css";

const ScheduleDemo = () => {
  return (
    <>
      <MainNavbar />
      <div className="ScheduleDemoContainer">
        <iframe
          src="https://cal.com/getbldx"
          width="100%"
          height="100%"
          title="Cal.com Scheduling Page"
        ></iframe>
      </div>
      <Footer />
    </>
  );
};

export default ScheduleDemo;
