import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { string } from "yup";

const baseURL = process.env.REACT_APP_BACKEND;

export const getProjectData = createAsyncThunk(
    'auth/getProject',
    async(_,{rejectWithValue}) => {
            try {
              const tokenString = localStorage.getItem("authToken");
              const token = tokenString ? JSON.parse(tokenString) : null;
              console.log("baseURL", baseURL);
              const response = await axios.get(`${baseURL}/api/projects/`, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });
              const project = response.data;
              const projectData = {
                project : project,
                company :  project[0].companies,
                subCompany :  project.subCompany,
                employees :  project[0].employees,
                schedule :  project[0]?.schedules[0]['_id']
              }

              console.log("project in hook", project);
              localStorage.setItem("currentSchedule", project[0]?.schedules[0]['_id']);

              return  projectData 
            } catch (err:any) {
              //setError(err instanceof Error ? err : new Error("Unknown error"));
              console.error("Error in useProjectData:", err);
              return rejectWithValue(err.message || "Unknown error");
            } 
    }
)