import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AdminState {
  projects: Array<{ id: string; name: string }>;
  loading: boolean;
}

const initialState: AdminState = {
  projects: [],
  loading: false,
};

const userSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setProjects(state, action: PayloadAction<Array<{ id: string; name: string }>>) {
      state.projects = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
  },
});

export const { setProjects, setLoading } = userSlice.actions;
export default userSlice.reducer;
