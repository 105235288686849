
// import { configureStore } from '@reduxjs/toolkit';
// import adminReducer from '../admin/redux/adminSlice';
// import clientReducer from '../client/redux/clientSlice';
// import authReducer from '../auth/redux/authSlice';
// import userReducer from '../users/redux/userSlice';
// import dashboardReducer from '../dashboard/redux/EmployeeTaskSlice';

import { configureStore } from "@reduxjs/toolkit";
import adminReducer from "../admin/redux/AdminSlice";
import clientReducer from "../client/redux/clientSlice";
import authReducer from "../auth/redux/AuthSlice";
import userReducer from "../users/redux/userSlice";
import dashboardReducer from "../dashboard/redux/EmployeeTaskSlice";

const store = configureStore({
  reducer: {
    admin: adminReducer,
    client: clientReducer,
    auth: authReducer,
    user: userReducer, // Shared state
    dashboard: dashboardReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
