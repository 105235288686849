import React from "react";
import MainNavbar from "./MainNavbar";
import CTASection from "./CTASection";
import Footer from "./Footer";
const FreeTools = () => {
  return (
    <>
      <MainNavbar />
      <CTASection />
      <Footer />
    </>
  );
};

export default FreeTools;
