import "./LandingStyles/DocumentsAI.css";
import documentAi from "../assets/documentAi.png";
import documentAi2 from "../assets/dai2.png";

export default function DocumentsAI() {
  return (
    <section className="ai-section">
      <div className="ai-container">
        <div className="ai-content">
          <div className="ai-visual">
            <img
              src={documentAi}
              alt="AI-powered document search interface showing architectural drawings"
              className="dai-interface-image"
            />
          </div>

          <div className="ai-text">
            <span className="ai-label">AI-Powered</span>
            <h2 className="ai-title">DOCUMENTS AI</h2>
            <p className="ai-description">
              Gone are the days of sifting through endless project files. With
              BLDX's AI-powered semantic search, just ask a question, and our
              system will retrieve the most relevant drawings, specifications,
              or reports—along with contextual explanations.
              <br />
              Find Answers, Not Just Files
              <br />
              <ul className="ul">
                <li>Ask in natural language.</li>
                <li>Highlighted insights.</li>
                <li>Smart suggestions.</li>
              </ul>
            </p>
          </div>
        </div>

        <div className="bt">
          <div className="ai-content">
            <div className="ai-text">
              <span className="ai-label">AI-Powered</span>
              <h2 className="ai-title">DOCUMENTS INSIGHTS</h2>
              <p className="ai-description">
                Search beyond keywords—our AI understands context and meaning.
                Locate exact sections within drawings, RFIs, or specifications
                instantly. Gain intelligent recommendations to prevent costly
                errors and rework.
              </p>
            </div>
            <div className="ai-visual mt-24">
              <img
                src={documentAi2}
                alt="AI-powered document search interface showing architectural drawings"
                className="dai-interface-image"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
