import "./LandingStyles/Reports.css";
import reports from "../assets/reports.png";
import labourSvg from "../assets/labourSvg.svg";
import drSvg from "../assets/drSvg.svg";
import projectSvg from "../assets/projectSvg.svg";
import { useState } from "react";
import img1 from "../assets/img1.jpg";
import img2 from "../assets/img2.jpg";
import img3 from "../assets/img3.jpg";

export default function Reports() {
  const [imageSrc, setImageSrc] = useState(img1);
  const [selectedCard, setSelectedCard] = useState("labour");
  return (
    <section className="reports-section">
      <div className="reports-container">
        {/* Header */}
        <div className="reports-header">
          <span className="reports-label">Reports</span>
          <h2 className="reports-title">
            Stay updated for seamless project execution.
          </h2>
          <p className="reports-description">
            Get actionable insights and detailed updates to ensure your project
            stays on track, every single day.
          </p>
        </div>

        {/* Content */}
        <div className="reports-content">
          {/* Left Side - Report Interface Image */}
          {/* <div className="reports-interface"> */}
          <div>
            <img
              src={imageSrc}
              alt="Daily report interface showing project activities and status"
              className="interface-image"
            />
          </div>

          {/* Right Side - Report Types */}
          <div className="report-types">
            <div
              className={`report-card ${
                selectedCard === "labour" ? "active" : ""
              }`}
              onClick={() => {
                setImageSrc(img1);
                setSelectedCard("labour");
              }}
            >
              <img src={labourSvg} alt="" />
              <h3 className="report-card-title">Labor and Material Report</h3>
              <p className="report-card-description">
                Gain insights into resource allocation. Ensure every resource is
                accounted for and efficiently utilized.
              </p>
            </div>

            <div
              className={`report-card ${
                selectedCard === "daily" ? "active" : ""
              }`}
              onClick={() => {
                setImageSrc(img2);
                setSelectedCard("daily");
              }}
            >
              <img src={drSvg} alt="" />
              <h3 className="report-card-title">Daily Report</h3>
              <p className="report-card-description">
                Monitor daily updates for informed decision-making. Keep your
                team aligned and minimize delays.
              </p>
            </div>

            <div
              className={`report-card ${
                selectedCard === "project" ? "active" : ""
              }`}
              onClick={() => {
                setImageSrc(img3);
                setSelectedCard("project");
              }}
            >
              <img src={projectSvg} alt="" />
              <h3 className="report-card-title">Project Status Report</h3>
              <p className="report-card-description">
                Track progress with comprehensive summaries. Share clear and
                actionable updates with stakeholders.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
