import "./LandingStyles/TrackingFeatures.css";
import project from "../assets/project.png";
import labour from "../assets/labour.png";
import constraints from "../assets/constraints.png";

export default function TrackingFeatures() {
  return (
    <section className="tracking-section">
      <div className="tracking-container">
        {/* Header */}
        <div className="tracking-header">
          <span className="tracking-label">Tracking</span>
          <h2 className="tracking-title">
            Progress, Constraint and Resource
            <br className="hide-sm" />
            Tracking made easier.
          </h2>
          <p className="tracking-description">
            Streamline project tracking with AI-driven tools that provide
            clarity on progress, remove bottlenecks, and optimize resource
            utilization effortlessly.
          </p>
        </div>

        {/* Feature Cards */}
        <div className="feature-cards">
          {/* Progress Tracking */}
          <div className="feature-card">
            {/* <div className="card-metrics">
              <div className="metric-group">
                <div className="metric-icon">⚡</div>
                <span className="metric-label">Project Status</span>
                <div className="metric-values">
                  <div className="metric-item">
                    <span className="metric-number">23%</span>
                    <span className="metric-text">Completed</span>
                  </div>
                  <div className="metric-item">
                    <span className="metric-number">40%</span>
                    <span className="metric-text">In Progress</span>
                  </div>
                </div>
              </div>
            </div> */}
            <img
              src={project}
              alt="Progress tracking interface showing project timeline and status"
              className="feature-image h260"
            />
            <div className="feature-card-text">
              <h3 className="feature-title">Progress Tracking</h3>
              <p className="feature-description">
                Stay updated on project milestones and timelines. Visualize your
                project's journey from start to finish with precision.
              </p>
            </div>
          </div>

          {/* Resource Tracking */}
          <div className="feature-card">
            {/* <div className="card-metrics">
              <div className="metric-group">
                <div className="metric-icon">⚡</div>
                <span className="metric-label">Labor Status</span>
                <div className="metric-values">
                  <div className="metric-item">
                    <span className="metric-number">54mh</span>
                    <span className="metric-text">Used</span>
                  </div>
                  <div className="metric-item">
                    <span className="metric-number">480mh</span>
                    <span className="metric-text">Planned</span>
                  </div>
                </div>
              </div>
              <div className="metric-group">
                <div className="metric-icon">⚡</div>
                <span className="metric-label">Material Status</span>
                <div className="metric-values">
                  <div className="metric-item">
                    <span className="metric-number">30%</span>
                    <span className="metric-text">Used</span>
                  </div>
                  <div className="metric-item">
                    <span className="metric-number">88%</span>
                    <span className="metric-text">Received</span>
                  </div>
                </div>
              </div>
            </div> */}
            <img
              src={labour}
              alt="Resource tracking interface showing labor and material metrics"
              className="feature-image"
            />
            <div className="feature-card-text">
              <h3 className="feature-title">Resource Tracking</h3>
              <p className="feature-description">
                Optimize material, labor, and equipment usage. Make data-driven
                decisions to reduce waste and maximize efficiency.
              </p>
            </div>
          </div>

          {/* Constraints Tracking */}
          <div className="feature-card">
            {/* <div className="card-metrics">
              <div className="metric-group">
                <div className="metric-icon">⚡</div>
                <span className="metric-label">Constraints</span>
                <div className="metric-values">
                  <div className="metric-item">
                    <span className="metric-number">3</span>
                    <span className="metric-text">Critical</span>
                  </div>
                  <div className="metric-item">
                    <span className="metric-number">5</span>
                    <span className="metric-text">Sub Critical</span>
                  </div>
                  <div className="metric-item">
                    <span className="metric-number">12</span>
                    <span className="metric-text">Normal</span>
                  </div>
                </div>
              </div>
            </div> */}
            <img
              src={constraints}
              alt="Constraints tracking interface showing bottlenecks and issues"
              className="feature-image h260"
            />
            <div className="feature-card-text">
              <h3 className="feature-title">Constraints Tracking</h3>
              <p className="feature-description">
                Identify and resolve bottlenecks efficiently. Proactively
                address challenges to maintain smooth operations.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
