// src/store/thunks/employeeTasksThunk.ts
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { 
    updateProjectStatus, 
    updateLaborStatus, 
    updateMaterialsStatus, 
    updateConstraintsStatus 
  } from '../../auth/redux/AuthSlice'; // Adjust import path as needed
const baseURL = process.env.REACT_APP_BACKEND;
const token = localStorage.getItem("token");



// Helper functions to calculate metrics from task data
function calculateProjectMetrics(tasks: any) {
    if (!tasks || tasks.length === 0) {
      return { completed: 0, pending: 0, inprogress:0};
    }
  
    // Calculate progress based on task progress values
    // const totalProgress = tasks.reduce((sum:any , task: any) => sum + (task.progress || 0), 0);
    // const progressPercentage = Math.round((totalProgress / (tasks.length * 100)) * 100) || 0;
    // const remainingProgress = 100 - progressPercentage;
    

        // Calculate progress percentages
        const completedTasks = tasks.filter((task: any) => task.progress === 100);
        const pendingTasks = tasks.filter((task: any) => task.progress === 0);
        const inProgressTasks = tasks.filter((task: any) => task.progress > 0 && task.progress < 100);
        
        // Calculate percentages
        const completedPercentage = Math.round((completedTasks.length / tasks.length) * 100) || 0;
        const pendingPercentage = Math.round((pendingTasks.length / tasks.length) * 100) || 0;
        const inProgressPercentage = Math.round((inProgressTasks.length / tasks.length) * 100) || 0;
    return {
    //   completed: progressPercentage,
    //   pending: remainingProgress
    completed: completedPercentage,
    pending: pendingPercentage,
    inprogress: inProgressPercentage
    };
  }
  
  function calculateLaborMetrics(tasks:any) {
    if (!tasks || tasks.length === 0) {
      return { actual: 0, planned: 0 };
    }
  
   
    const task = tasks[0];
  
    if (!task?.specification?.labour) {
      return { actual: 0, planned: 0, percentage: 0 };
    }
    
    // Calculate the total planned value - same structure as calculateTaskTotals
    const plannedTotal = task.specification.labour.reduce(
      (sum: number, labour: any) => sum + parseInt(labour.planned || "0"),
      0
    );
    
    // Calculate the total actual value - same structure as calculateTaskTotals
    const actualTotal = task.specification.labour.reduce(
      (sum: number, labour: any) =>
        sum +
        labour.actual
          .filter((entry: any) => entry?.status === "approved")
          .reduce(
            (innerSum: number, entry: any) =>
              innerSum +
              parseInt(entry?.skilled || "0") +
              parseInt(entry?.unskilled || "0"),
            0
          ),
      0
    );
   
    
      // Calculate the percentage
      const percentage = plannedTotal > 0 ? Math.round((actualTotal / plannedTotal) * 100) : 0;

      const maxValue = Math.max(plannedTotal, actualTotal, 100); // Use 100 as fallback minimum
  
      // Calculate normalized percentages for both planned and actual
      const plannedPercentage = Math.round((plannedTotal / maxValue) * 100);
      const actualPercentage = Math.round((actualTotal / maxValue) * 100);
    
      // Store values in localStorage if needed
      localStorage.setItem("plannedTotal", plannedTotal.toString());
      localStorage.setItem("actualTotal", actualTotal.toString());
      localStorage.setItem("actualPlannedPercentage", percentage.toString());
    
      return {
        planned: plannedPercentage,
        actual: actualPercentage,
        percentage
      };
  }
  
  function calculateMaterialsMetrics(tasks: any) {
    if (!tasks || tasks.length === 0) {
      return { planned: 0, received: 0, used: 0 };
    }
  
    // You'll need to adjust this based on your actual data structure
    // This is a placeholder implementation that you should modify
    // to match your specific data model
    // const materialsData = tasks.reduce((acc: any, task: any) => {
    //   // Extract materials data based on your task structure
    //   const planned = task.materials?.planned || 100;
    //   const received = task.materials?.received || 0;
    //   const used = task.materials?.used || 0;
      
    //   return {
    //     planned: acc.planned + planned,
    //     received: acc.received + received,
    //     used: acc.used + used
    //   };
    // }, { planned: 0, received: 0, used: 0 });


      // Extract materials data based on the correct task structure
  const materialsData = tasks.reduce(
    (totals: any, task: any) => {
      // Check if the task has material specifications
      if (task?.specification?.material) {
        task.specification.material.forEach((material: any) => {
          // Sum up the planned and received quantities
          totals.planned += Number(material.planned || 0);
          totals.received += Number(material.received || 0);
          
          // Sum up the used quantities from approved consumption records
          totals.used += material.consumptionHistory
            .filter((record: any) => record.status === "approved")
            .reduce(
              (sum: number, record: any) => sum + Number(record.Qty || 0),
              0
            );
        });
      }
      return totals;
    },
    { planned: 0, received: 0, used: 0 }
  );
  
    // Calculate percentages
    const maxValue = Math.max(
      materialsData.planned, 
      materialsData.received, 
      materialsData.used,
      100 // Fallback to ensure we don't divide by zero
    );
    
    return {
      planned: Math.round((materialsData.planned / maxValue) * 100),
      received: Math.round((materialsData.received / maxValue) * 100),
      used: Math.round((materialsData.used / maxValue) * 100)
    };
  }
  
  function calculateConstraintsMetrics(tasks: any) {
    if (!tasks || tasks.length === 0) {
      return { total: 0, high: 0, medium: 0, low: 0 };
    }
  
    // Filter tasks that have constraints
    const constraints = tasks.filter((task:any) => task.hasConstraint || task.constraints?.length > 0);
    
    // Count by severity
    const high = constraints.filter((task:any) => task.constraintSeverity === 'high' || task.constraints?.some((c:any) => c.severity === 'high')).length;
    const medium = constraints.filter((task:any) => task.constraintSeverity === 'medium' || task.constraints?.some((c:any) => c.severity === 'medium')).length;
    const low = constraints.filter((task:any) => task.constraintSeverity === 'low' || task.constraints?.some((c:any) => c.severity === 'low')).length;
    
    return {
      total: constraints.length,
      high,
      medium,
      low
    };
  }
// Add this helper function
export const updateTask = createAsyncThunk(
  "dashboard/updateTask",
  async ({ task }: any, thunkAPI) => {
    const token = localStorage.getItem("token");
    const response = await fetch(
      `${baseURL}/api/tasks/${task.specification.taskId}`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(task),
      }
    );

    if (!response.ok) {
      throw new Error("Failed to update task progress");
    }

    const result = await thunkAPI.dispatch(fetchEmployeeTasks());
    return response.json();
  }
);

export const updateTasksProgress = createAsyncThunk(
  "dashboard/updateTasksProgress",
  async ({ taskId, progress,isBatch }: any, thunkAPI) => {
    const token = localStorage.getItem("authToken");
    const response = await fetch(`${baseURL}/api/tasks/progress/${taskId}`, {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(progress,isBatch),
    });

    if (!response.ok) {
      throw new Error("Failed to update task progress");
    }

    const result = await thunkAPI.dispatch(fetchEmployeeTasks());
    return response.json();
  }
);

// Define the async thunk
// export const fetchEmployeeTasks = createAsyncThunk(
//     "dashboard/fetchEmployeeTasks",
//     async (_, { rejectWithValue }) => {
//         try {
//             const tokenString = localStorage.getItem("authToken");
//             const employeeId = localStorage.getItem("UserEmployeeId");
//             const token = tokenString ? JSON.parse(tokenString) : null;

//             let userInfo: any = localStorage.getItem("userInfo");
//             let position = JSON.parse(userInfo).position;
//             let apiEndPoint: string;

//             if (position === "SC") {
//                 apiEndPoint = `${baseURL}/api/tasks/employee/${employeeId}`;
//             } else if (position === "GC") {
//                 apiEndPoint = `${baseURL}/api/tasks/pending/approval`;
//             } else {
//                 throw new Error("Invalid position");
//             }

//             //starts pending state , used in slices
//             const response = await axios.get(apiEndPoint, {
//                 headers: {
//                     Authorization: `Bearer ${token}`,
//                 },
//             });
//             //loading state , used in slices
//             return response.data; // This will be the payload for `fulfilled`
//         } catch (err: any) {
//             console.error("Error in fetchEmployeeTasks:", err);
//             //rejected state , used in slices
//             return rejectWithValue(err.message || "Unknown error");
//         }
//     }
// );


// Fetch employee tasks with metrics calculation
export const fetchEmployeeTasks = createAsyncThunk(

    "dashboard/fetchEmployeeTasks",
    async (_, { dispatch, rejectWithValue }) => {
        try {
            const tokenString = localStorage.getItem("authToken");
            const employeeId = localStorage.getItem("UserEmployeeId");
            const token = tokenString ? JSON.parse(tokenString) : null;

            let userInfo: any = localStorage.getItem("userInfo");
            let position = JSON.parse(userInfo).position;
            let apiEndPoint: string;

            if (position === "SC") {
                apiEndPoint = `${baseURL}/api/tasks/employee/${employeeId}`;
            } else if (position === "GC") {
                apiEndPoint = `${baseURL}/api/tasks/pending/approval`;
            } else {
                throw new Error("Invalid position");
            }

            // Fetch tasks
            const response = await axios.get(apiEndPoint, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            
            const tasks = response.data;
            
            // Calculate all dashboard metrics after tasks are fetched
            
            // 1. Project Status metrics
            const projectMetrics = calculateProjectMetrics(tasks);
            dispatch(updateProjectStatus({
                completed: projectMetrics.completed,
                pending: projectMetrics.pending,
                inprogress: projectMetrics.inprogress
            }));
            
            // 2. Labor Status metrics
            const laborMetrics = calculateLaborMetrics(tasks);
            dispatch(updateLaborStatus({
                actual: laborMetrics.actual,
                planned: laborMetrics.planned
            }));
            
            // 3. Materials Status metrics
            const materialsMetrics = calculateMaterialsMetrics(tasks);
            dispatch(updateMaterialsStatus({
                planned: materialsMetrics.planned,
                received: materialsMetrics.received,
                used: materialsMetrics.used
            }));
            
            // 4. Constraints metrics
            const constraintsMetrics = calculateConstraintsMetrics(tasks);
            dispatch(updateConstraintsStatus({
                total: constraintsMetrics.total,
                high: constraintsMetrics.high,
                medium: constraintsMetrics.medium,
                low: constraintsMetrics.low
            }));
            
            // For backward compatibility, also update localStorage
            localStorage.setItem("homeProgressPercentage", projectMetrics.completed.toString());
            localStorage.setItem("homePendingProgressPercentage", projectMetrics.pending.toString());
            
            localStorage.setItem("actualTotal", laborMetrics.actual.toString());
            localStorage.setItem("plannedTotal", laborMetrics.planned.toString());
            
            localStorage.setItem("percentagePlanned", materialsMetrics.planned.toString());
            localStorage.setItem("percentageReceived", materialsMetrics.received.toString());
            localStorage.setItem("percentageUsed", materialsMetrics.used.toString());
            
            localStorage.setItem("severityCounts", constraintsMetrics.total.toString());
            localStorage.setItem("severityCountsHigh", constraintsMetrics.high.toString());
            localStorage.setItem("severityCountsMed", constraintsMetrics.medium.toString());
            localStorage.setItem("severityCountsLow", constraintsMetrics.low.toString());
            
            return tasks; // Return the tasks for the dashboard/fetchEmployeeTasks fulfilled action
        } catch (err: any) {
            console.error("Error in fetchEmployeeTasks:", err);
            return rejectWithValue(err.message || "Unknown error");
        }

    }
);


export const upsertTaskAttributesAndUpdateTaskProgress = createAsyncThunk(
  "dashboard/upsertTaskAttributesAndUpdateTaskProgress",
  async (
    { taskAttributeId, taskAttributeUpsertPayload, taskId, progress,isBatchUpdate }: any,
    thunkAPI
  ) => {
    // First API call
    const response = await axios.put(
      `${baseURL}/api/tasks/attributes/upsert/${taskAttributeId}`,
      taskAttributeUpsertPayload,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // Second API call
    await updateTasksProgress({ taskId, progress,isBatchUpdate });

    // Fetch fresh data using thunkAPI.dispatch
    const result = await thunkAPI.dispatch(fetchEmployeeTasks());
    return result.payload;
  }
);

export const updateTaskAttribute = createAsyncThunk(
  "dashboard/updateTaskAttribute",
  async ({ taskAttributeId, payload }: any, thunkAPI) => {
    // First API call
    const response = await fetch(
      `${baseURL}/api/tasks/attributes/upsert/${taskAttributeId}`,
      {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: payload,
      }
    );

    if (!response.ok) {
      throw new Error("Failed to decline task approval");
    }

    // Second API call not needed since declined
    //await updateTaskProgress(taskProgress);

    // Fetch fresh data using thunkAPI.dispatch
    const result = await thunkAPI.dispatch(fetchEmployeeTasks());
    return result.payload;
  }
);

export const updateTaskAttributeWithFile = createAsyncThunk(
  "dashboard/updateTaskAttribute",
  async ({ taskAttributeId, payload }: any, thunkAPI) => {
    try {
      const response = await axios.put(
        `${baseURL}/api/tasks/attributes/upsert/${taskAttributeId}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      //await updateTaskProgress(taskProgress);

      // Fetch fresh data using thunkAPI.dispatch
      const result = await thunkAPI.dispatch(fetchEmployeeTasks());
      return result.payload;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data || "An error occurred"
      );
    }
  }
);

export const updateTaskAttributeBatchWithFile = createAsyncThunk(
  "dashboard/updateTaskAttributeBatch",
  async ({ taskAttributeID, taskId, formData }: any, thunkAPI) => {
    try {
      const response = await axios.put(
        `${baseURL}/api/tasks/attributes/batch/${taskAttributeID}/${taskId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const result = await thunkAPI.dispatch(fetchEmployeeTasks());
      return result.payload;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data || "An error occurred"
      );
    }
  }
);

export const createTaskAttributeForTask = createAsyncThunk(
  "dashboard/createTaskAttributeForTask",
  async ({ payload }: any, thunkAPI) => {
    console.log("payload", payload);
    try {
      const response = await axios.post(
        `${baseURL}/api/tasks/attributes`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("Task attribute created successfully:", response.data);
      const result = await thunkAPI.dispatch(fetchEmployeeTasks());
      //return response.data; // Return the data for further processing
      return result.payload;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(
        error.response?.data || "An error occurred"
      );
    }
  }
);
