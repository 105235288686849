import { useEffect, useRef } from "react";
import "./LandingStyles/Solutions.css";
import { Link } from "react-router-dom";

export default function Solutions() {
  const videoRef = useRef<HTMLIFrameElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const handleIntersect = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Play video when in view
          videoRef.current?.contentWindow?.postMessage(
            '{"event":"command","func":"playVideo","args":""}',
            "*"
          );
        } else {
          // Pause video when out of view
          videoRef.current?.contentWindow?.postMessage(
            '{"event":"command","func":"pauseVideo","args":""}',
            "*"
          );
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersect, options);

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <section className="solutions-section">
      <div className="solutions-container">
        <span className="solutions-label">Solutions</span>

        <h1 className="solutions-title">
          Power of AI Transforming
          <br />
          Construction Efficiencies
        </h1>

        <p className="solutions-description">
          Transform your construction projects with AI-driven automation—from
          planning and scheduling to execution and workforce optimization.
          Minimize delays, reduce costs, and enhance collaboration with
          data-backed insights at every stage of the project life cycle.
          <br />
          <b>Smarter Schedules - Optimized Workforce – Data-Driven Decisions</b>
        </p>

        <Link to="/schedule-demo">
          <button className="demo-button">
            SCHEDULE DEMO
            <span className="arrow">→</span>
          </button>
        </Link>

        <div className="video-container" ref={containerRef}>
          <iframe
            ref={videoRef}
            src="https://www.youtube.com/embed/hTEWzEw7rL8?enablejsapi=1&autoplay=1&controls=0"
            title="Construction AI Platform Demo"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="demo-video"
          />
        </div>
      </div>
    </section>
  );
}
