import React, { useEffect, useState } from "react";
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  Button,
  Grid,
  Paper,
  Typography,
  Box,
  InputLabel,
  Container,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { CloudUpload } from "@material-ui/icons";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import {
  Material,
  FormValues,
  MaterialPlannerFormProps,
} from "../types/interface";

import axios from "axios";
import Loader from "../LogoLoader";
import { useSnackbar } from "../../core/contexts/SnackbarProvider";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";
import {
  createTaskAttributeForTask,
  updateTaskAttribute,
  updateTaskAttributeWithFile,
} from "../redux/EmployeeTaskThunk";
import UpdateExistingMaterial from "./MaterialDrawer/UpdateExistingMaterial";
import AddNewMaterial from "./MaterialDrawer/AddNewMaterial";

const MaterialPlannerForm: React.FC<MaterialPlannerFormProps> = ({
  open,
  onClose,
  activity,
  onTriggerUpdate,
}) => {
  const baseURL = process.env.REACT_APP_BACKEND;
  const snackbar = useSnackbar();
  const [disableSave, setDisableSave] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [proof, setProof] = useState<File[]>([]);
  console.log("activity", activity);
  const units = [
    { value: "m3", label: "Cubic Meter (m³)" },
    { value: "yd3", label: "Cubic Yard (yd³)" },
    { value: "liter", label: "Liter (L)" },
    { value: "ton", label: "Metric Ton (t)" },
    { value: "kg", label: "Kilogram (kg)" },
    { value: "meter", label: "Meter (m)" },
    { value: "km", label: "Kilometer (km)" },
    { value: "ft", label: "Foot (ft)" },
    { value: "yd", label: "Yard (yd)" },
    { value: "m2", label: "Square Meter (m²)" },
    { value: "ft2", label: "Square Foot (ft²)" },
    { value: "yd2", label: "Square Yard (yd²)" },
    { value: "pieces", label: "Pieces" },
    { value: "bag", label: "Bag" },
    { value: "roll", label: "Roll" },
    { value: "bundle", label: "Bundle" },
    { value: "box", label: "Box" },
  ];
  const dispatch = useDispatch<AppDispatch>();
  const [orderStatuses, setOrderStatuses] = useState<string[]>([]);

  const handleOrderStatusChange = (value: string, index: number) => {
    setOrderStatuses((prevStatuses) => {
      const updatedStatuses = [...prevStatuses];
      updatedStatuses[index] = value;

      // Update materialListItem.status
      setValue(`materials.${index}.orderStatus`, value);

      return updatedStatuses;
    });
  };

  useEffect(() => {
    console.log("orderStatuses updated:", orderStatuses);
  }, [orderStatuses]);

  const {
    control,
    handleSubmit: handleNewMaterialSubmit,
    setValue,
    getValues,
    formState: { errors, isSubmitting, isDirty },
    trigger, // Add this
  } = useForm<FormValues>({
    defaultValues: {
      materials: [],
    },
    mode: "all",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "materials",
  });

  const userID = localStorage.getItem("UserEmployeeId");

  const groupNewMaterials=(newMaterialPayload:any)=>{
    console.log("newMaterialPayload",newMaterialPayload);
  }

  const onNewMaterialSubmit = async (data: FormValues) => {
    console.log(Object.keys(errors).length, "addnewmaterialform232ddd", errors);

    return new Promise(async (resolve, reject) => {
      setDisableSave(true);
      console.log(Object.keys(errors).length, "addnewmaterialform232", errors);

      //setLoading(true);
      const user = localStorage.getItem("user");
      const folder = user ? JSON.parse(user).profile.name : "general";
      const transformedData = data.materials.map((material) => ({
        type: "material",
        insert: {
          name: material.name || null,
          planned: material.quantity || null,
          received: material?.received || null,
          status: material.orderStatus || null,
          unit: material.unit || null,
          folderName: folder,
          orderHistory: [
            {
              status: material.orderStatus || null,
              Qty: material.received ? material.received : material.quantity,
              orderPlaceDate: material.orderPlacedDate
                ? new Date(material.orderPlacedDate)
                : null,
              user: userID,
              ETA: material.eta ? new Date(material.eta) : null,
              invoice: material.proof ? material.proof.name : null,
              invoiceNo: material.invoiceNo ? material.invoiceNo : "",
            },
          ],
        },
      }));
      const payload = {
        data: transformedData,
      };

      groupNewMaterials(transformedData);

      const formData = new FormData();
      if (proof && proof.length > 0) {
        proof.forEach((file) => {
          formData.append("files", file); // Append each file individually
        });
      }
      formData.append("body", JSON.stringify(payload));
      console.log("Material-payload", formData);
      console.log("Transformed Data:", payload);

      try {
        const tokenString = localStorage.getItem("authToken");
        const token = tokenString ? JSON.parse(tokenString) : null;

        if (!token) {
          alert("Authorization token is missing!");

          setLoading(false);
          return;
        }

        let taskIdToUse = activity?.specification?._id;

        console.log("taskIdToUse", taskIdToUse);

        if (!activity.specification || activity.specification.length === 0) {
          const initPayload = {
            taskId: activity._id,
            material: [],
            labour: [],
            constraints: [],
            DRApproval: [],
          };
          console.log("initPayload", initPayload);

          try {
            const resultAction = await dispatch(
              createTaskAttributeForTask({ payload: initPayload })
            ).unwrap();
            snackbar.success("Task attributes has been created successfully");
            console.log("Specification initialized:", resultAction); // Changed from resultAction.data to resultAction.payload
            taskIdToUse = resultAction.payload.specification._id; // Changed from resultAction.data to resultAction.payload
            console.log("taskIdToUse", taskIdToUse);

            addNewMaterialWithProofThunk(taskIdToUse, formData)
              .then((data: any) => {
                console.log("Update successful:", data?.payload);
                snackbar.success("Material has been added successfully");
                resolve(data?.payload);
              })
              .catch((err) => {
                console.error("Update failed:", err.payload);
                snackbar.error("Material has not been added");
                reject(err?.payload);
              });
          } catch (err) {
            console.log("error in creating task attribute", err);
            snackbar.error("failed to create task attribute"); // Display the error message
          }
        } else {
          console.log("Material-payload", formData);
          addNewMaterialWithProofThunk(taskIdToUse, formData)
            .then((data: any) => {
              console.log("Update successful:", data?.payload);
              snackbar.success("Material has been added successfully");
              resolve(data?.payload);
            })
            .catch((err) => {
              console.error("Update failed:", err.payload);
              snackbar.error("Material has not been added");
              reject(err?.payload);
            });
        }

        //setLoading(false);
        setDisableSave(false);
      } catch (error) {
        console.error("Error submitting add Material API form:", error);
        snackbar.error("Failed to add material. Please try again.");

        setDisableSave(false);

        setLoading(false);
      }
    });
  };

  const handleFileUpload = (file: File, index: number) => {
    console.log("file", file);
    setProof((prevProof) => [...prevProof, file]);
  };

  const addNewMaterialWithProofThunk = async (
    taskIdToUse: any,
    formData: any
  ) => {
    try {
      let upsertResult = await dispatch(
        updateTaskAttributeWithFile({
          taskAttributeId: taskIdToUse,
          payload: formData,
        })
      ).unwrap();
    } catch (e) {
      console.log("error in adding data to task attribute", e);
    }
  };

  const {
    control: exisitingMaterialControl,
    formState: exisitingMaterialFormState,
    handleSubmit: exisitingMaterialHandleSubmit,
    formState: {
      errors: exisitingMaterialErrors,
      isSubmitting: isExisitingMaterialSubmitting,
      isDirty: isExisitingMaterialDirty,
    },
    trigger: existingMaterialTrigger,
  } = useForm({
    defaultValues: {
      materials: activity?.specification?.material.map((material: any) => {
        const lastOrderHistory =
          material.orderHistory?.[material.orderHistory.length - 1];
        return {
          _id: material._id,
          name: material.name || "",
          unit: material.unit || "",
          planned: material.planned || "",
          received: material?.received || null,
          quantity: lastOrderHistory?.Qty || "",
          createAt: material.createAt,
          orderHistory: material.orderHistory || [],
          consumptionHistory: material.consumptionHistory || [],
          orderStatus: material.status
            ? material.status
            : material.orderStatus
            ? material.orderStatus
            : "",

          opd: lastOrderHistory?.orderPlaceDate?.split("T")[0] || "",
          eta: lastOrderHistory?.ETA?.split("T")[0] || "",
        };
      }),
    },
    mode: "all",
  });

  const onExisitingMaterialSubmit = async (data: any) => {
    return new Promise(async (resolve, reject) => {
      setDisableSave(true);
      setLoading(true);
      console.log("existing material Form Data:", data);

      const existingMaterialdata = data.materials.map((material: any) => ({
        type: "material",
        update: {
          _id: material._id,
          name: material.name || null,
          planned: material.planned || null,
          received: material?.received || null,
          status: material.orderStatus || null,
          unit: material.unit || null,
          createAt: material?.createAt,
          orderHistory: [
            ...(material.orderHistory || []),
            {
              status: material.orderStatus || null,
              Qty: material.quantity ? parseInt(material.quantity, 10) : null,
              received: material?.received || null,
              orderPlaceDate: material.opd,
              user: userID,
              ETA: material.eta ? new Date(material.eta) : null,
              invoice: material.proof ? material.proof.name : null,
              invoiceNo: material.invoiceNo ? material.invoiceNo : "",
            },
          ],
          consumptionHistory: material.consumptionHistory || [],
        },
      }));

      const existingMaterialDataPayload = {
        data: existingMaterialdata,
      };

      console.log("existingMaterialDataPayload", existingMaterialDataPayload);

      const formData = new FormData();

      if (proof && proof.length > 0) {
        proof.forEach((file) => {
          formData.append("files", file); // Append each file individually
        });
      }

      formData.append("body", JSON.stringify(existingMaterialDataPayload));

      console.log("Material", formData);

      try {
        let taskIdToUse = activity?.specification?._id;

        console.log("taskIdToUse", taskIdToUse);

        let result = await dispatch(
          updateTaskAttributeWithFile({
            taskAttributeId: taskIdToUse,
            payload: formData,
          })
        ).unwrap();
        console.log("API Response:", result);
        snackbar.success("Material has been added successfully");
        setDisableSave(false);
        setLoading(false);
        resolve(result);
      } catch (error) {
        setLoading(false);
        console.error("Error submitting add Material API form:", error);
        snackbar.error("Failed to add material. Please try again.");

        setDisableSave(false);
        reject(error);
      }
    });
  };

  const { dirtyFields } = exisitingMaterialFormState;

  const handleSubmitBothForms = async () => {
    let promiseArr: Promise<any>[] = [];
    promiseArr = [];
    const isValid = await trigger();
    console.log("Form validation result:", isValid);

    // Get all field values
    const formValues = getValues();
    console.log("Current form values:", formValues);

    // Get validation errors for materials specifically
    const isNewMaterialsValid = await trigger(); // Ensure trigger is defined and accessible
    const isExistingMaterialsValid = await existingMaterialTrigger(); // Check if exisitingMaterialControl is correctly defined
    console.log("Materials validation result:", {
      isNewMaterialsValid,
      isExistingMaterialsValid,
    }); // Log both validation results

    console.log(isDirty, "error", errors.materials);

    if (isExistingMaterialsValid && isExisitingMaterialDirty) {
      promiseArr.push(
        exisitingMaterialHandleSubmit(onExisitingMaterialSubmit)()
      );
    }

    if (Object.keys(errors).length === 0 && isDirty) {
      setDisableSave(true);
      await promiseArr.push(handleNewMaterialSubmit(onNewMaterialSubmit)());
    }

    if (promiseArr.length) {
      try {
        let result = await Promise.allSettled(promiseArr);
        console.log("onNewMaterialSubmit", result);
        let hasError = result.some((result) => result.status === "rejected");
        if (!hasError) {
          onClose(); // Only call onClose if there are no errors
        } else {
          console.error("One or more submissions failed:", result);
        }
      } catch (e) {
        console.error("Error in one of the submissions:", e);
      }
    }
  };

  const existingMaterialNames = Array.isArray(activity?.specification?.material)
    ? activity.specification.material
        .filter((material: any) => material?.name)
        .map((material: any) => material.name.toLowerCase())
    : [];

  const validateReceivedQuantity = (value: string, materialListItem: any) => {
    const totalQuantity = parseFloat(materialListItem.planned) || 0; // Default to 0 if quantity is invalid

    const parsedValue = parseFloat(value); // Parse received value

    if (isNaN(parsedValue)) {
      return "Received Quantity must be a valid number"; // Validate value as a number
    }

    if (parsedValue > totalQuantity) {
      return "Received Quantity must be less than or equal to Total Quantity";
    }

    // if (parsedValue <= oldReceived) {
    //   return "Received Quantity must be greater than the old received quantity";
    // }

    return true; // Valid input
  };

  return (
    <>
      {loading && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 1000,
            overflow: "hidden",
            height: "100%",
          }}
        >
          <Loader isLoading={loading} size={160} />
        </Box>
      )}

      <Container
        className="bg-red-200"
        maxWidth="md"
        style={{
          overflow: loading ? "hidden" : "auto",
          minWidth: "600px",
        }}
      >
        <Paper elevation={3}>
          <Box
            p={2}
            display="flex"
            justifyContent="space-between"
            borderBottom="1px solid grey"
          >
            <Typography variant="h6" className="text-red-200">
              {activity.title}
            </Typography>

            <Button variant="outlined" size="small" onClick={onClose}>
              Close
            </Button>
          </Box>

          <Box p={2}>
            <Typography variant="h6" gutterBottom>
              Planned Materials
            </Typography>

            <form
              onSubmit={exisitingMaterialHandleSubmit(
                onExisitingMaterialSubmit
              )}
            >
              {activity.specification &&
                activity.specification.material.map(
                  (materialListItem: any, index: number) => (
                    <Box mb={4} key={index}>
                      <Typography variant="subtitle1" gutterBottom>
                        Material {index + 1}
                      </Typography>

                      <Grid container spacing={2} mb={2}>
                        <Grid item xs={6} sm={4}>
                          <Controller
                            name={`materials.${index}.name`}
                            control={exisitingMaterialControl}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                disabled
                                fullWidth
                                label="Material Name"
                                variant="outlined"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={6} sm={3}>
                          <Controller
                            name={`materials.${index}.quantity`}
                            control={exisitingMaterialControl}
                            rules={{
                              required: "Quantity is required",
                              pattern: {
                                value: /^[0-9]+$/,
                                message: "Quantity must be a number",
                              },
                            }}
                            render={({ field, fieldState }) => (
                              <TextField
                                {...field}
                                fullWidth
                                label="Total Quantity"
                                variant="outlined"
                                placeholder="00"
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message}
                                disabled={
                                  activity.specification.DRApproval.length !== 0
                                }
                              />
                            )}
                          />
                        </Grid>
                        {(materialListItem.status === "partially_received" ||
                          orderStatuses[index] === "partially_received") && (
                          <Grid item xs={6} sm={3}>
                            <Controller
                              name={`materials.${index}.received`}
                              control={exisitingMaterialControl}
                              rules={{
                                required: "Received Quantity is required",
                                pattern: {
                                  value: /^[0-9]+$/,
                                  message: "Received Quantity must be a number",
                                },
                                validate: (value) =>
                                  validateReceivedQuantity(
                                    value,
                                    materialListItem
                                  ),
                              }}
                              render={({ field, fieldState }) => (
                                <TextField
                                  {...field}
                                  fullWidth
                                  label="Received Quantity"
                                  variant="outlined"
                                  placeholder="00"
                                  error={!!fieldState.error}
                                  helperText={fieldState.error?.message}
                                />
                              )}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} sm={2}>
                          <Controller
                            name={`materials.${index}.unit`}
                            control={exisitingMaterialControl}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                disabled
                                fullWidth
                                label="Unit"
                                variant="outlined"
                              />
                            )}
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={2} mb={2}>
                        <Grid item xs={12} sm={4}>
                          <Controller
                            name={`materials.${index}.orderStatus`}
                            control={exisitingMaterialControl}
                            rules={{ required: "Order Status is required" }}
                            render={({ field, fieldState }) => (
                              <FormControl
                                fullWidth
                                variant="outlined"
                                error={!!fieldState.error}
                              >
                                <InputLabel>Order Status</InputLabel>
                                <Select
                                  {...field}
                                  label="Order Status"
                                  onChange={(e) => {
                                    field.onChange(e); // Update form state
                                    handleOrderStatusChange(
                                      e.target.value,
                                      index
                                    ); // Update local state
                                  }}
                                  value={field.value || ""}
                                >
                                  <MenuItem value="">
                                    Select Order Status
                                  </MenuItem>
                                  <MenuItem value="Order_Placed">
                                    Order Placed
                                  </MenuItem>
                                  <MenuItem value="In_Transit">
                                    In Transit
                                  </MenuItem>
                                  <MenuItem value="Received">
                                    Received 100%
                                  </MenuItem>
                                  <MenuItem value="partially_received">
                                    Partially Received
                                  </MenuItem>
                                  <MenuItem value="Yet_to_Place_Order">
                                    Yet to Place Order
                                  </MenuItem>
                                </Select>
                                {fieldState.error && (
                                  <Typography variant="body2" color="error">
                                    {fieldState.error.message}
                                  </Typography>
                                )}
                              </FormControl>
                            )}
                          />
                        </Grid>

                        <Grid item xs={12} sm={4}>
                          <Controller
                            name={`materials.${index}.opd`}
                            control={exisitingMaterialControl}
                            rules={{
                              required:
                                materialListItem.status !==
                                  "Yet_to_Place_Order" &&
                                orderStatuses[index] !== "Yet_to_Place_Order"
                                  ? "Order Placed Date is required"
                                  : false,
                            }}
                            render={({ field, fieldState }) => (
                              <TextField
                                {...field}
                                fullWidth
                                label="Order Placed Date"
                                type="date"
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message}
                                // disabled={
                                //   orderStatuses[index] ===
                                //     "Yet_to_Place_Order"
                                //     ||
                                //   materialListItem.status ===
                                //     "Yet_to_Place_Order"
                                // }
                                disabled={
                                  orderStatuses[index] ===
                                    "Yet_to_Place_Order" ||
                                  getValues(
                                    `materials.${index}.orderStatus`
                                  ) === "Yet_to_Place_Order"
                                }
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <Controller
                            name={`materials.${index}.eta`}
                            control={exisitingMaterialControl}
                            rules={{
                              required:
                                materialListItem.status !== "Received" &&
                                orderStatuses[index] !== "Received" &&
                                materialListItem.status !==
                                  "Yet_to_Place_Order" &&
                                orderStatuses[index] !== "Yet_to_Place_Order"
                                  ? "ETA is required"
                                  : false,
                            }}
                            render={({ field, fieldState }) => (
                              <TextField
                                {...field}
                                fullWidth
                                label="ETA"
                                type="date"
                                InputLabelProps={{ shrink: true }}
                                variant="outlined"
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message}
                                disabled={
                                  orderStatuses[index] === "Received" ||
                                  orderStatuses[index] === "Yet_to_Place_Order"
                                  //  ||
                                  // materialListItem.status === "Received" ||
                                  // materialListItem.status ===
                                  //   "Yet_to_Place_Order"
                                }
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  )
                )}
            </form>
            {/* <UpdateExistingMaterial activity={activity} /> */}
            <Typography variant="h6" gutterBottom>
              Add New Materials
            </Typography>

            <form onSubmit={handleNewMaterialSubmit(onNewMaterialSubmit)}>
              {fields.length > 0 &&
                fields.map((field, index) => (
                  <Box key={field.id} mb={4} position="relative">
                    <Typography variant="subtitle1" gutterBottom>
                      Material {String(index + 1).padStart(2, "0")}
                    </Typography>
                    <IconButton
                      onClick={() => remove(index)}
                      size="small"
                      sx={{
                        position: "absolute",
                        right: 0,
                        top: 0,
                        "&:hover": {
                          color: "blue",
                        },
                      }}
                    >
                      <CloseIcon />
                    </IconButton>

                    <Grid container spacing={2} mb={2}>
                      <Grid item xs={6} sm={4}>
                        <Controller
                          name={`materials.${index}.name`}
                          control={control}
                          rules={{
                            required: "Material Name is required",
                            validate: (value) =>
                              value &&
                              !existingMaterialNames.includes(
                                value.toLowerCase()
                              )
                                ? true
                                : "This material already exists.",
                          }}
                          render={({ field, fieldState: { error } }) => (
                            <TextField
                              {...field}
                              fullWidth
                              label="Material Name"
                              variant="outlined"
                              placeholder="Enter material name"
                              error={!!error}
                              helperText={error?.message}
                              onChange={(e) => {
                                const newValue = e.target.value || ""; // Handle undefined/null safely
                                field.onChange(newValue); // Update the field value
                              }}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={6} sm={3}>
                        <Controller
                          name={`materials.${index}.quantity`}
                          control={control}
                          rules={{
                            required: "Quantity is required",
                            pattern: {
                              value: /^[0-9]+$/,
                              message: "Quantity must be a number",
                            },
                          }}
                          render={({ field, fieldState: { error } }) => (
                            <TextField
                              {...field}
                              fullWidth
                              label="Total Quantity"
                              variant="outlined"
                              placeholder="00"
                              error={!!error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </Grid>

                      {orderStatuses[index] === "partially_received" && (
                        <Grid item xs={6} sm={3}>
                          <Controller
                            name={`materials.${index}.received`}
                            control={control}
                            rules={{
                              required: "Received Quantity is required",
                              pattern: {
                                value: /^[0-9]+$/,
                                message: "Received Quantity must be a number",
                              },
                            }}
                            render={({ field, fieldState }) => (
                              <TextField
                                {...field}
                                fullWidth
                                label="Received Quantity"
                                variant="outlined"
                                placeholder="00"
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message}
                              />
                            )}
                          />
                        </Grid>
                      )}

                      <Grid item xs={12} sm={2}>
                        <Controller
                          name={`materials.${index}.unit`}
                          control={control}
                          rules={{ required: "Unit is required" }}
                          render={({ field, fieldState: { error } }) => (
                            <FormControl
                              fullWidth
                              variant="outlined"
                              error={!!error}
                            >
                              <InputLabel>Unit</InputLabel>
                              <Select {...field} label="Unit">
                                {units.map((unit) => (
                                  <MenuItem key={unit.value} value={unit.value}>
                                    {unit.label}
                                  </MenuItem>
                                ))}
                              </Select>
                              {/* <Select {...field} label="Unit">
                                <MenuItem value="bags">Bags</MenuItem>
                                <MenuItem value="pieces">Pieces</MenuItem>
                                <MenuItem value="kg">Kg</MenuItem>
                              </Select> */}
                              {error && (
                                <Typography color="error">
                                  {error.message}
                                </Typography>
                              )}
                            </FormControl>
                          )}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={2} mb={2}>
                      <Grid item xs={12} sm={4}>
                        <Controller
                          name={`materials.${index}.orderStatus`}
                          control={control}
                          rules={{ required: "Order status is required" }}
                          render={({ field, fieldState: { error } }) => (
                            <FormControl
                              fullWidth
                              variant="outlined"
                              error={!!error}
                            >
                              <InputLabel>Order Status</InputLabel>
                              <Select
                                {...field}
                                label="Order Status"
                                onChange={(e) => {
                                  field.onChange(e); // Update form state
                                  handleOrderStatusChange(
                                    e.target.value,
                                    index
                                  ); // Update local state
                                }}
                                value={field.value || ""}
                              >
                                <MenuItem value="">
                                  Select Order Status
                                </MenuItem>
                                <MenuItem value="Order_Placed">
                                  Order Placed
                                </MenuItem>
                                <MenuItem value="In_Transit">
                                  In Transit
                                </MenuItem>
                                <MenuItem value="Received">
                                  Received 100%
                                </MenuItem>
                                <MenuItem value="partially_received">
                                  Partially Received
                                </MenuItem>
                                <MenuItem value="Yet_to_Place_Order">
                                  Yet to Place Order
                                </MenuItem>
                              </Select>
                              {error && (
                                <Typography color="error">
                                  {error.message}
                                </Typography>
                              )}
                            </FormControl>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Controller
                          name={`materials.${index}.orderPlacedDate`}
                          control={control}
                          rules={{
                            required:
                              orderStatuses[index] !== "Yet_to_Place_Order"
                                ? "Order placed date is required"
                                : false,
                          }}
                          render={({ field, fieldState: { error } }) => (
                            <TextField
                              {...field}
                              fullWidth
                              label="Order Placed Date"
                              type="date"
                              InputLabelProps={{ shrink: true }}
                              variant="outlined"
                              error={!!error}
                              helperText={error?.message}
                              disabled={
                                orderStatuses[index] === "Yet_to_Place_Order"
                              }
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Controller
                          name={`materials.${index}.eta`}
                          control={control}
                          rules={{
                            required:
                              orderStatuses[index] !== "Received" &&
                              orderStatuses[index] !== "Yet_to_Place_Order"
                                ? "ETA is required"
                                : false,
                            validate: (value) =>
                              orderStatuses[index] === "Received" ||
                              orderStatuses[index] === "Yet_to_Place_Order" ||
                              new Date(value) >
                                new Date(
                                  getValues(
                                    `materials.${index}.orderPlacedDate`
                                  )
                                ) ||
                              "ETA must be after Order Placed Date",
                          }}
                          render={({ field, fieldState: { error } }) => (
                            <TextField
                              {...field}
                              fullWidth
                              label="ETA"
                              type="date"
                              InputLabelProps={{ shrink: true }}
                              variant="outlined"
                              error={!!error}
                              helperText={error?.message}
                              disabled={
                                orderStatuses[index] === "Received" ||
                                orderStatuses[index] === "Yet_to_Place_Order"
                              }
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={8} sm={4}>
                        <Controller
                          name={`materials.${index}.invoiceNo`}
                          control={control}
                          render={({ field, fieldState: { error } }) => (
                            <TextField
                              {...field}
                              value={field.value || ""}
                              onChange={(e) =>
                                field.onChange(String(e.target.value))
                              }
                              fullWidth
                              label="Invoice No."
                              variant="outlined"
                              error={!!error}
                              helperText={error?.message}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>

                    <Box
                      border={2}
                      borderColor="grey.300"
                      borderRadius={4}
                      p={2}
                      textAlign="center"
                      sx={{
                        position: "relative",
                        cursor: "pointer",
                        "&:hover": { backgroundColor: "grey.100" },
                      }}
                    >
                      <input
                        type="file"
                        accept=".jpg,.jpeg,.png,.gif,.svg"
                        style={{
                          opacity: 0,
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          zIndex: 10,
                          cursor: "pointer",
                        }}
                        onChange={(event) => {
                          if (event.target.files?.[0]) {
                            handleFileUpload(event.target.files[0], index);
                          }
                        }}
                      />
                      <CloudUpload fontSize="large" color="primary" />
                      <Typography variant="body1" gutterBottom>
                        {proof.length > 0 ? proof[0].name : "Upload Proof"}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Click to upload or drag and drop. Supported: SVG, PNG,
                        JPG, GIF (max. 3MB)
                      </Typography>
                    </Box>
                  </Box>
                ))}

              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  append({
                    name: "",
                    quantity: "",
                    unit: "",
                    orderStatus: "",
                    orderPlacedDate: "",
                    eta: "",
                    proof: null,
                    invoiceNo: "",
                  })
                }
                sx={{ marginTop: 2 }}
              >
                Add Another Material
              </Button>
            </form>
            {/* <AddNewMaterial activity={activity} /> */}

            <Box mt={4} display="flex" justifyContent="space-between">
              <Button variant="outlined" onClick={onClose}>
                Cancel
              </Button>

              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmitBothForms}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Paper>
      </Container>
    </>
  );
};

export default MaterialPlannerForm;
function trigger() {
  throw new Error("Function not implemented.");
}
