import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {Activity} from '../types/interface';
import { fetchEmployeeTasks, createTaskAttributeForTask, updateTaskAttribute, updateTask,upsertTaskAttributesAndUpdateTaskProgress, updateTaskAttributeBatchWithFile } from './EmployeeTaskThunk';
import { act } from "react-dom/test-utils";
import { de } from "date-fns/locale";


interface EmployeeTasksState {
    tasks: Activity[];
    isLoading: boolean;
    error: string | null;
}

const initialState: EmployeeTasksState = {
    tasks: [],
    isLoading: false,
    error: null,
};

const employeeTasksSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchEmployeeTasks.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchEmployeeTasks.fulfilled, (state, action) => {
                state.isLoading = false;
                state.tasks = action.payload;
            })
            .addCase(upsertTaskAttributesAndUpdateTaskProgress.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(upsertTaskAttributesAndUpdateTaskProgress.fulfilled, (state, action) => {
                state.isLoading = false;
                state.tasks = action.payload;
            })
            .addCase(updateTaskAttribute.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateTaskAttribute.fulfilled, (state, action) => {
                state.isLoading = false;
                state.tasks = action.payload;
            })
            .addCase(updateTask.fulfilled, (state, action) => {
                state.isLoading = false;
            }).addCase(updateTask.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(updateTaskAttributeBatchWithFile.fulfilled,(state,action)=>{
                state.isLoading = false;
                state.tasks = action.payload;
            })
            .addCase(updateTaskAttributeBatchWithFile.pending,(state,action)=>{
                state.isLoading = true;
            })
    },
});

export default employeeTasksSlice.reducer