import React from "react";
import MainNavbar from "./MainNavbar";
import CTASection from "./CTASection";
import Footer from "./Footer";
import TCECaseStudy from "./TCECaseStudy";

const CaseStudy = () => {
  return (
    <>
      <MainNavbar />
      <div className="Case-Study-Container">
        <TCECaseStudy/>
      </div>
      <CTASection />
      <Footer />
    </>
  );
};

export default CaseStudy;
